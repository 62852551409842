@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:wght@500&family=Teko:wght@500&display=swap');

.FifaLogo{
  width: 60%;
  height: 30%;
  margin: 2px 10px;
  @media screen 
  and (max-width: 450px) {
  width: 70%;
  height: 30%;
  margin: 10px auto;
}
@media screen 
and (max-width: 375px) {
width: 100%;
height: 30%;
margin: 10px auto;
}
}
.aviso{
background: none;
position: absolute;
box-shadow: none;
border: none;
left: 40vw;
top: 8px; 
display: flex;
justify-content: center;
z-index: 1000;
background-color: #bf0811;
color: #FFF;
font-size: .5em;
padding: 0;
border-radius: 8px;
opacity: 0.8;
@media screen 
and (max-width: 360px) {
width: 40vw;
left: 12vw;
h1{
  font-size: 1em
}
}
@media screen 
and (min-width: 361px)
and (max-width: 430px) {
width: 47vw;
left: 12vw;
h1{
  font-size: 1.3em
}
}
@media screen 
and (min-width: 430px) 
and (max-width: 991px){
width: 45vw;
left: 16vw;
h1{
  font-size: 1.5em
}
}

@media screen 
and (min-width: 992px)
and (max-width: 1199px){
width: 40vw;
left: 14vw;
h1{
  font-size: 1.5em
}
}
@media screen 
and (min-width: 1200px)
{
width: 20vw;
left: 14vw;
h1{
  font-size: 1.5em
}
}
}

.card {
height: 100%;
// border: none;
opacity: 100%;
background-color: transparent;
font-family: Teko, sans-serif;
border: 5px solid #000;
border-radius: 12px 12px 10px 10px;

opacity: 1;
&.card--not-full-height {
height: auto;
}
}
.card-body {
padding: 0 0 2px 0;
height: 100%;
width: 100%;

background-color: #FFF;
}
.card_grid{
margin: 5px 0;
@media screen 
and (max-width: 991px){
padding: 0;
}
}
.headtohead_grid{
padding: 0;
margin: 0;
}
.card__title {
position: relative;
display: flex;
justify-content: center;
flex-direction: row;
background-color: #000;
padding: 25px 5px 45px 5px;
border-radius: 5px 5px 10px 10px;
margin: -1px -1px 5px -1px; 
text-transform: uppercase;
@media screen 
and (max-width: 430px){
padding: 25px 5px 35px 5px;
}
h2 {
font-size: .9em;
margin-bottom: 0;
padding: 10px 0 0 0;
font-family: 'Bebas Neue', cursive;
letter-spacing: 1px;
@media screen 
and (max-width: 430px) {
  font-size: .8em;
}
@media screen 
and (min-width: 430px) 
and (max-width: 770px){
  font-size: 1.4em; 

}
// @media screen 
// and (min-width: 1800px) {
//   font-size: 1.7em;

// }
@media screen 
and (min-width: 1500px)
and (max-width: 1799px) {
  font-size: 1.15em;
  line-height: 1.5em;
}
@media screen 
and (min-width: 992px)
and (max-width: 1300px){
  font-size: .9em;
}
@media screen 
and (min-width: 1299px)
and (max-width: 1499px) {
  font-size: .85em;
  line-height: 1em;
}
}
h3{
margin: 0px auto;
font-size: .7em;
color: #fff;
@media screen 
and (max-width: 430px){
  font-size: .8em;
}
}
@media screen 
and (min-width: 1800px){
font-size: 1.2em;
}
@media screen 
and (min-width: 770px)
and (max-width: 840px) {
font-size: .8em;
}

}
.errorMsg{
color: #FFF600;
font-size: 3em;
font-family: Teko, sans-serif;
}
.NoMatchs{
  width: 25%;
  height: 25%;
  margin: 5vh auto;
  filter: brightness(60%);
  @media screen 
  and (min-width: 300px)
  and (max-width: 600px){
  width: 100%;
  height: 100%;
  transform: rotate(90deg);
  margin: 25vh auto;
}
h1{
  color: #FFF600;
  display: flex;
  font-size: 1em;
  margin: 0 auto;
  width: 12vw;
  line-height: 1.2em;
  @media screen and (max-width: 570px) {
    position: absolute;
    transform: rotate(270deg);
    bottom: 170px;
    left: -110px;
    width: 35vw;
  }
}
}
.players-grid{
  // display: grid;
  // grid-template-areas: 
  // "homeHeaderImg playersNames awayHeaderImg"
  // "homeHeaderImg teams awayHeaderImg"
  display: flex;
  justify-content: center;
  padding-top: 35px;
  @media screen and (min-width: 1900px) {
    padding-top: 45px;
  }
}
.players{
padding: 0px;
margin: 0 auto;
width: 25vw;
h2{
color: #FFF600;  
font-family: 'Bebas Neue', cursive;
font-style: italic;
margin: 10px;
width: fit-content;
display: flex;
justify-content: center;
}
.divisor{
color: deeppink;
}

@media screen 
and (min-width: 320px)
and (max-width: 575px){
width: 100vw;
}
@media screen 
and (min-width: 575px)
and (max-width: 700px){

width: 75vw;
}
@media screen 
and (min-width: 700px)
and (max-width: 991px){

width: 75vw;
}
@media screen 
and (min-width: 992px)
and (max-width: 1199px) {
width: 37vw;
h2{
  margin-top: 0px;
}
}
@media screen 
and (min-width: 1200px)
and (max-width: 1450px) {
  width: 27vw;
}

}
.homeHeaderImg{
  grid-area: homeHeaderImg;
}
.awayHeaderImg{
  grid-area: awayHeaderImg;
  transform: scaleX(-1);
}
.awayHeaderImg, .homeHeaderImg{
  width: 18%;
  height: 18%;
  z-index: 10;
  user-select: none;
  @media screen 
and (max-width: 450px) {
  width: 20%;
  height: 20%;
}
}
.time{
color: #fff;
width: fit-content;
position: absolute;
top: 16%;
right: 1%;
font-size: 1.2em;
margin: 0;
@media screen 
and (max-width: 430px) {
  font-size: 1.2em;
  right: 2%;
}

@media screen 
and (min-width: 1700px) {
  font-size: 1.5em;
  top: 20%;
  right: 0%;
}

}
.squared-corner-theme {
.card-body {
border-radius: 0;
}
}
.blocks-with-shadow-theme {

.card-body {
box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
}
}
.subcard {
border-radius: 20px 20px 5px 5px;
background-color: #ffff;
font-family: 'Teko', sans-serif;
border: none;
font-size: 1em;
text-transform: uppercase;
color: #000;
margin: 0 5px;
padding: 0px;
-webkit-box-shadow: 3px 3px 5px 2px rgba(0,0,0,0.24);
-moz-box-shadow: 3px 3px 5px 2px rgba(0,0,0,0.24);
box-shadow: 3px 3px 5px 2px rgba(0,0,0,0.24);
table{
display: flex;
justify-content: center;
margin-top: 3px;
tr, td, th{
  padding: 0 2px;
}

}
@media screen
and (min-width: 320px)  
and (max-width: 359px){
font-size: .82em;
table{
  tr, td, th{
    padding: 0 1px;
  }
}
}
@media screen
and (min-width: 360px)  
and (max-width: 400px){
font-size: .9em;
table{
  tr, td, th{
    padding: 0 2px;
  }
}
}
@media screen 
and (min-width: 570px) 
and (max-width: 775px){
font-size: .84em;
table{
  tr, td, th{
    padding: 0 5px;
  }
}
}

@media screen 
and (min-width: 776px) 
and (max-width: 990px) {
font-size: 1.25em;
padding: 0;
table{
  tr, td,th{
    padding: 0 8px;
  }
}
}
@media screen 
and (min-width: 1000px) 
and (max-width: 1199px) {
font-size: .91em;

table{
  tr, td, th{
    padding: 0 2px;
  }
}
}
@media screen 
and (min-width: 1200px) 
and (max-width: 1315px) {
font-size: .8em;
table{
  tr, td, th{
    padding: 0 2px;
  }
}
}
@media screen 
and (min-width: 1316px) 
and (max-width: 1499px) {
font-size: .82em;
table{
  tr, td, th{
    padding: 0 2px;
  }
}
}
@media screen 
and (min-width: 1500px) {
font-size: .96em;
table{
  tr, td, th{
    padding: 0 3px;
  }
}
}
@media screen 
and (min-width: 1745px) {
font-size: 1.1em;
table{
  tr, td, th{
    padding: 0 5px;
  }
}
}
@media screen 
and (min-width: 1800px) {
font-size: 1.2em;
table{
  tr, td, th{
    padding: 0 4px;
  }
}
}
@media screen 
and (min-width: 2150px) {
font-size: 1.4em;
table{
  tr, td, th{
    padding: 0 5px;
  }
}
}
}
.table_header{
font-size: .9em;
}
.card-link{
color: #fff600;
grid-area: playerNames;
}
.card-link:hover{
color: deeppink;
}
.card_header{
display: flex;
flex-direction: row;
justify-content: center;

}

.subhead {
text-transform: none;
font-family: 'Bebas Neue', cursive;
letter-spacing: 1px;
padding: 0;
grid-area: teams;
}

.score{
color: #FFF;
padding: 0 3px;

}
// Chance colors
.high_chance{
color: #009045;
}

.low_chance{ 
color: #bf0811;
}

.medium_chance{
color: #e29417;
}
//Progress Bars
.progress {
background: rgb(218,0,0);
background: linear-gradient(180deg, rgba(218,0,0,1) 30%, rgba(91,18,18,1) 77%);
border-radius: 10px;
margin: 15px 0px;
height: 15px;
width: 50%;
display: flex;
align-items: center;
justify-content: flex-start;
position: relative;
font-family: 'Bebas Neue', cursive;
font-style: italic;
p{
position: absolute;
color: #FFF;
z-index: 1;
font-size: 1em;
left: 30%;
bottom: 7px;
@media screen 
and (min-width: 751px)
and (max-width: 990px){
  font-size: 1.2em;
}
@media screen 
and (max-width: 430px) {
  font-size: .9em;

}
@media screen 
and (min-width: 1200px)
and (max-width: 1499px) {
  font-size: 1em;
} 
@media screen 
and (min-width: 1500px) {
  font-size: 1.2em;

}

@media screen 
and (min-width: 1900px) {
  font-size: 1.2em;

}
}

h3{
position: absolute;
color: #FFF;
z-index: 1;
font-size: 1em;
left: 45%;
bottom: -10px;
@media screen 
and (min-width: 751px)
and (max-width: 990px){
  font-size: 1.2em;

}
@media screen 
and (max-width: 430px) {
  font-size: 1em;

}
@media screen 
and (min-width: 1200px)
and (max-width: 1499px) {
  font-size: 1em;

} 
@media screen 
and (min-width: 1500px) {
  font-size: 1.2em;

}
@media screen 
and (min-width: 1900px) {
  font-size: 1.2em;
  bottom: -9px;
}
}

@media screen 
and (min-width: 2000px) {
height: 20px;
font-size: 1.2em;
}

}

.progress-done {
background: rgb(0,196,219);
background: linear-gradient(180deg, rgba(0,196,219,1) 6%, rgba(18,16,90,1) 88%);
display: flex;
align-items: flex-start;
justify-content: flex-start;
height: 100%;
transition: 1s ease 0.3s;
}
.progressbars{
display: flex;
justify-content: center;
position: absolute;
bottom: 0px;
width: 100%;

@media screen 
and (min-width: 350px)
and (max-width: 750px) {
max-width: 90vw;
}
}

.progress-data{
color: #FFF;
margin: 10px;
font-size: 1.2em;
@media screen 
and (max-width: 410px) {
margin: 10px ;
}
@media screen 
and (min-width: 1900px) {
font-size: 1.2em;
}
}

.attacks{
display: flex;
justify-content: center;
margin: 0px;
width: 100%;
}

.subcard_title{
font-size: 1em;
line-height: 1.5em;
display: flex;
justify-content: center;
background-color: #000;
color: #fff;
border-radius: 10px 10px 0 0;
font-family: 'Bebas Neue', cursive;
font-style: italic;
}

//Calculadora de Dutching
.dutching{
display: flex;
justify-content: center;
background: #fff;
color: #000;
border-radius: 0 0 15px 15px;

@media screen 
and (min-width: 2000px){
  width: 50vw;
  margin-left: -15vw;
}
}
.external-dutching{
  display: flex;
  justify-content: center;
  background: #fff;
  color: #000;
  border-radius: 0 0 15px 15px;
}

.dutching-label{
  display: flex;
  input{
  margin: 2px 20px;
  border: 1px solid #000;
  border-radius: 20px;
  width: 15vw;
  @media screen 
  and (max-width: 500px){
  width: 25vw;
  margin: 2px 15px;
}
  @media screen 
  and (min-width: 501px)
  and (max-width: 850px){
  width: 20vw;
}
//   @media screen 
//   and (min-width: 850px)
//   and (max-width: 850px){
//   width: 20vw;
// }
  @media screen 
  and (min-width: 1800px){
  width: 18vw;
  font-size: 2em;
  margin: 0 80px
  // margin: 0 auto;
}
  }

}
.subcard-dutching{
  border-radius: 20px 20px 5px 5px;
  background-color: #ffff;
  font-family: 'Teko', sans-serif;
  border: none;
  font-size: 1.3em;
  text-transform: uppercase;
  color: #000;
  margin: 0 5px;
  padding: 0px;
  -webkit-box-shadow: 3px 3px 5px 2px rgba(0,0,0,0.24);
  -moz-box-shadow: 3px 3px 5px 2px rgba(0,0,0,0.24);
  box-shadow: 3px 3px 5px 2px rgba(0,0,0,0.24);
  input{
    border: 2px solid #000;
    border-radius: 20px;
  }
  table{
  display: flex;
  justify-content: center;
  margin-top: 3px;
  tr, td, th{
    padding: 5px;
  }
  }
  @media screen
  and (min-width: 320px)  
  and (max-width: 359px){
  font-size: .85em;
  table{
    tr, td, th{
      padding: 5px;
    }
  }
  }
  @media screen
  and (min-width: 360px)  
  and (max-width: 400px){
  font-size: 1em;
  table{
    tr, td, th{
      padding: 5px;
    }
  }
  }
  @media screen 
  and (min-width: 570px) 
  and (max-width: 775px){
  font-size: 1.5em;

  table{
    tr, td, th{
      padding: 5px 10px;
    }
  }
  }
  @media screen 
  and (min-width: 776px) 
  and (max-width: 999px) {
  font-size: 2em;
  padding: 0;
  input{
    margin: -100px;
  }
  table{
    tr, td,th{
      padding: 5px 60px;
    }
  }
  }
  @media screen 
  and (min-width: 1000px) 
  and (max-width: 1199px) {
  font-size: 2em;
  input{
    margin: -130px;
  }
  table{
    tr, td, th{
      padding: 5px 100px;
    }
  }
  }
  @media screen 
  and (min-width: 1200px)
  and (max-width: 1800px) {
  font-size: 2.5em;
  input{
    margin: -130px;
  }
  table{
    tr, td, th{
      padding: 5px 120px;
    }
  }
  }
  @media screen 
  and (min-width: 1801px)
  and (max-width: 2000px) {
  font-size: 2.5em;
  input{
    margin: -150px;
  }
  table{
    tr, td, th{
      padding: 5px 150px;
    }
  }
  }
  @media screen 
  and (min-width: 2001px) {
    font-size: 3.5em;
    input{
      margin: -220px;
      width: 25vw;
    }
    table{
      tr, td, th{
        padding: 5px 220px;
      }
    }
  }

  }
.calcheader-external{
background-color: #000;
color: #fff;
padding: 20px;
display: flex;
flex-direction: row;
justify-self: center;
margin: 0 auto;
@media screen 
and (max-width: 820px) {
  margin: 0;
}
span{
color: #FFF600;
}
h2{
text-transform: uppercase;
font-family: 'Bebas Neue', cursive;
margin: 10px 0 0 30px;
@media screen 
and (max-width: 460px) {
  margin: 10px 0 0 0;
  font-size: 1em;
}
}
h5{
  @media screen 
  and (min-width: 700px) {
    margin: 0 auto;
  }
}
}
.calcheader{
background-color: #000;
color: #fff;
padding: 20px;
display: flex;
flex-direction: row;
justify-self: center;
span{
color: #FFF600;
}
h2{
text-transform: uppercase;
font-family: 'Bebas Neue', cursive;
margin: 10px 0 0 30px;
@media screen 
and (max-width: 460px) {
  margin: 10px 0 0 0;
  font-size: 1.7em;
}
}
@media screen 
and (min-width: 2000px){
  width: 50vw;
  margin-left: -15vw;
  padding: 40px;
}
}
.form-dutching{
border: 5px solid #000;
border-radius: 10px 10px 20px 20px;
font-family: 'Teko', sans-serif;
font-style: italic;
position: relative;
top: 20vh;

input{
background-color: #fff;
color: #000;
padding: 10px 5px;
border: none;
border-radius: 10px;
position: absolute;
top: 46px;
right: 90px;
width: 35%;
@media screen 
and (max-width: 410px) {
  right: 35px;
  height: 30px;
  top: 50px;
}
@media screen 
and (min-width: 2000px){
  width: 30vw;
  top: 75px;
  right: -100px;
}
@media screen 
and (min-width: 2300px){
  width: 32vw;
  top: 72px;
  right: -214px;
}
}
}
.dutch-title{
background: rgb(4,0,255);
background: linear-gradient(90deg, rgb(4, 1, 170) 18%, rgba(0,0,0,1) 100%);
color: #fff;
font-size: .9em;
position: relative;
padding: 0 40px 0 10px;
top: -10px;
right: 25px;
border-radius: 5px 0 20px 0;
width: 50vw;
opacity: 85%;
font-family: 'Teko', sans-serif;
font-style: normal;
@media screen 
and (min-width: 790px){
  width: 22vw;
  font-size: 1.2em;
  padding: 0 20px 0 10px;
}
@media screen 
and (min-width: 2000px){
  width: 10vw;
  font-size: 1.2em;
  padding: 0 20px 0 10px;
}
}

.dutching-card{
font-size: 1.2em;
line-height: 1.5em;
color: #000;
margin: 0;
padding: 0;
table{
margin: 0;
padding: 0;
tr, td, th{
  padding: 0 2px;
  @media screen 
  and (max-width: 460px) {
    padding: 0 2px;
  }
}
}
@media screen 
and (max-width: 460px) {
font-size: .9em;
}
@media screen 
and (min-width: 2000px) {
  font-size: 2em;
  table{
    tr,td,th{
      padding: 5px 15px;
    }
  }
}
.form-external-dutching{
  border: 5px solid #000;
  border-radius: 10px 10px 20px 20px;
  font-family: 'Teko', sans-serif;
  font-style: italic;
  position: relative;
  top: 20vh;
  }
.external-dutching-card{
font-size: 1.2em;
line-height: 1.5em;
color: #000;
margin: 0;
padding: 0;

table{
margin: 0;
padding: 0;
tr, td, th{
  padding: 0 20px;
  @media screen 
  and (max-width: 460px) {
    padding: 0 2px;
  }
}
}
@media screen 
and (max-width: 460px) {
font-size: .9em;
}
@media screen 
and (min-width: 2000px) {
  font-size: 2em;
  table{
    tr,td,th{
    padding: 5px 100px;
    }
  }
}
}
}
.modal-content{
background: none;
border: none;
}
.modal-body{
padding: 10px;
}
.modal-backdrop.show {
  opacity: 0.8;
}
.logo{
display: flex;
width: 45%;
height: 45%;
margin-bottom: 15px;
@media screen 
and (max-width: 460px) {
width: 70%;
height: 90%;
margin: 0;
}
@media screen 
and (min-width: 2000px){
width: 35%;
height: 35%;
}
}

.logoLiga{
  width: 100px;
  position: absolute;
  top: -8%;
  left: 0;
  @media screen 
  and (max-width: 420px) {
  width: 75px;
  top: -5%;
}
}
.logoLigaVariant{
  width: 100px;
  position: absolute;
  top: -4%;
  left: 0;
  @media screen 
  and (max-width: 550px) {
  width: 65px;
}
}
.liga10{
background: rgb(194, 1, 155);
background: linear-gradient(90deg, rgb(194, 1, 155) 0%, rgba(0,0,0,1) 88%);
font-family: 'Teko', sans-serif;
position: absolute;
font-size: .9em;
top: 8px;
left: -3px;
padding-right: 40px;
border-radius: 20px 0 100px 5px;
color: #fff;
@media screen 
and (max-width: 430px) {
top: 5px;
}
}
.liga8{
background: rgb(0,212,255);
background: linear-gradient(90deg, rgba(0,212,255,1) 3%, rgba(9,9,121,1) 58%, rgba(2,0,36,1) 84%, rgba(0,0,0,1) 93%);
font-family: 'Teko', sans-serif;
position: absolute;
font-size: .9em;
top: 8px;
left: -3px;
padding-right: 40px;
border-radius: 20px 0 100px 5px;
color: #fff;
@media screen 
and (max-width: 430px) {
top: 5px;
}
}
.liga12{
background: rgb(17,140,44);
background: linear-gradient(90deg, rgba(17,140,44,1) 13%, rgba(16,90,32,1) 82%, rgba(0,0,0,1) 93%);
font-family: 'Teko', sans-serif;
position: absolute;
font-size: .9em;
top: 8px;
left: -3px;
padding-right: 40px;
border-radius: 20px 0 100px 5px;
color: #fff;
@media screen 
and (max-width: 430px) {
top: 5px;
}
}
.liga12gt{
  background: rgb(143, 16, 122);
  background: linear-gradient(90deg, rgb(192, 25, 142) 13%, rgb(194, 8, 184) 82%, rgba(0,0,0,1) 93%);
  font-family: 'Teko', sans-serif;
  position: absolute;
  font-size: .9em;
  top: 8px;
  left: -3px;
  padding-right: 40px;
  border-radius: 20px 0 100px 5px;
  color: #fff;
  @media screen 
  and (max-width: 430px) {
  top: 5px;
  }
  }
//Head To Head Card

.search{
  border: 2px solid #FFF;
  border-radius: 25px;
  padding: 20px 40px;
  margin: 0 10px 15px 0;
  width: 33vw;
  font-family: 'Teko', sans-serif;
  font-size: 20px;
  color: #FFF !important;
  background: none !important;
  text-transform: capitalize;
  @media screen 
  and (min-width: 1000px) 
  and (max-width: 1600px){
    width: 30vw;
  }
  @media screen 
  and (max-width: 475px){
    padding: 13px 20px;
    width: 41vw;
    font-size: 16px;
  }
}

.search::placeholder{
  color: #FFF;
  font-family: 'Teko', sans-serif;
  font-size: 20px;
  @media screen 
  and (max-width: 475px){
    font-size: 16px;
  }
}
.search:-webkit-autofill,
.search:-webkit-autofill:hover, 
.search:-webkit-autofill:focus{
  border: 1px solid #FFF;
  // -webkit-text-fill-color: #FFF600;
  // -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
  font-size: 1em;
}
.searchLeague{
  grid-area: searchLeague;
  width: fit-content;
  margin: 15px auto;
  h1{
    width: fit-content;
    margin: 5px auto;
    color: #FFF;
    font-size: 2.2em;
    font-family: 'Bebas Neue', cursive;
  }
}
.search-home{
  grid-area: search-home; 
}
.search-away{
  grid-area: search-away; 
}
.searchInput{
  display: grid;
  grid-area: searchInput;
  grid-template-areas: "search-home search-away submit-btn ";
  @media screen 
  and (max-width: 1000px) {
  grid-template-areas: "search-home search-away "
  "submit-btn submit-btn";
  margin: 0;

}
}
.search-headtohead{
  display: grid;
  grid-template-areas: 
  "searchLeague searchLeague searchLeague"
  "searchInput searchInput searchInput";
  width: 75vw;
  margin: 0 auto;
  @media screen 
  and (max-width: 1000px) {
  margin: 0;

}
}

.team{
color: #fff;
font-family: 'Bebas Neue', cursive;
@media screen 
and (max-width: 430px) {
margin-top: 22px;
}

@media screen 
and (max-width: 780px) {
margin-top: 20px;
}
}

.header__title{
position: relative;
display: flex;
flex-direction: row;
justify-content: center;
background-color: #000;
padding: 35px 5px 10px 5px;
border-radius: 5px 5px 10px 10px;
margin: -1px -2px 5px -2px;  
text-transform: uppercase;
h1{
@media screen 
and (max-width: 430px) {
  font-size: 2.5em;
}
}

}
.head-title{
font-size: 3em;
color: #FFF600;
padding: 75px 20px 10px 20px;
display: flex;
flex-direction: column;
justify-content: center;
h2{
font-size: 2.2em;
margin:  9px 5px;
font-family: 'Bebas Neue', cursive;
}
@media screen 
and (min-width: 781px) 
and (max-width: 1200px) {
font-size: 2em;
}
@media screen 
and (min-width: 431px)
and (max-width: 780px) {
font-size: 1em;
margin: 0;
padding: 35px 5px 0px 5px;
}
@media screen 
and (max-width: 430px) {
font-size: .8em;
margin: 0;
padding: 20px 5px 0px 5px;
}
.divisor-headToHead{
color: #FFF600;
margin-top: 8px;
grid-area: divisor;
@media screen 
and (max-width: 430px) {
  margin-top: 20px;
}
@media screen 
and (max-width: 780px) {
  margin-top: 19px;
}
}
}
.players-headtohead{
display: flex;
// margin: 0;
// width: 50vw;
}

.btns{
display: flex;
flex-direction: row;
justify-content: center;
position: relative;
z-index: 10;
margin: 0 ; 
@media screen 
and (max-width: 430px) {
margin-top: -25px;
}
@media screen 
and (min-width: 430px) 
and (max-width: 770px) {
  margin-top: -20px;
}
}

.headtohead_title{
font-size: 2.5em;
display: flex;
margin: 0 auto;
justify-content: center;
background-color: #000;
// width: 50vw;

color: #fff;
border-radius: 5px;
@media screen 
and (max-width: 430px) {
font-size: 1.5em;
}

}
.liga-headToHead10{
background: rgb(194, 1, 155);
background: linear-gradient(90deg, rgb(194, 1, 155) 0%, rgba(0,0,0,1) 88%);
font-family: 'Teko', sans-serif;
position: absolute;
font-size: 1.5em;
top: 15px;
left: -3px;
padding-right: 100px;
border-radius: 20px 0 100px 5px;
color: #fff;
@media screen 
and (max-width: 1300px) {
font-size: 1em;
}
@media screen 
and (max-width: 430px) {
font-size: .9em;
padding-right: 50px;
left: 0;
}
}
.liga-headToHead8{
background: rgb(0,212,255);
background: linear-gradient(90deg, rgba(0,212,255,1) 3%, rgba(9,9,121,1) 58%, rgba(2,0,36,1) 84%, rgba(0,0,0,1) 93%);
font-family: 'Teko', sans-serif;
position: absolute;
font-size: 1.5em;
top: 15px;
left: -3px;
padding-right: 100px;
border-radius: 20px 0 100px 5px;
color: #fff;
@media screen 
and (max-width: 1300px) {
font-size: 1em;
}
@media screen 
and (max-width: 430px) {
font-size: .9em;
padding-right: 50px;
left: 0;
}
}
//General Component
.general, .toHead, .lastfive{
background-color: #000;
color: #FFF;
padding: 20px 10px;
margin: 20px 0 ;
@media screen 
and (max-width: 430px) {
padding: 0px 0;
}
}
.general-content{
display: flex;
justify-content: center;
// @media screen 
// and (max-width: 650px) {
//   grid-template-areas:
//   "bars bars"
//   "home away";
// }
h2{
font-size: 5em;
font-family: 'Bebas Neue', cursive;
margin: 30px auto;
padding: 20px 0;
@media screen 
and (max-width: 1200px) {
font-size: 3.5em;
}
@media screen 
and (max-width: 1099px) {
font-size: 2.5em;
}
@media screen 
and (max-width: 430px) {
font-size: 1.2em;
padding: 0;
}
@media screen
and (min-width: 431px) 
and (max-width: 749px){
font-size: 1.5em;
}
}
}
.over-content{
h2{
font-family: 'Bebas Neue', cursive;
font-size: 5em;
margin: 10px auto;
@media screen 
and (max-width: 1200px) {
font-size: 3.5em;
}
@media screen 
and (max-width: 1000px) {
font-size: 2.5em;
}
@media screen 
and (max-width: 500px) {
font-size: 1.5em;
margin: 0px auto;
}
}
}
.lastfive-content{
  display: grid;
  grid-template-areas: "homeImg home away awayImg";
  gap: 0 10px;
  h2{
    font-size: 5em;
    font-family: 'Bebas Neue', cursive;
    margin: 30px auto;
    padding: 20px 0;
    @media screen 
    and (max-width: 1200px) {
    font-size: 3.5em;
    }
    @media screen 
    and (max-width: 1099px) {
    font-size: 2.5em;
    }
    @media screen 
    and (max-width: 430px) {
    font-size: 1.2em;
    padding: 0;
    }
    @media screen
    and (min-width: 431px) 
    and (max-width: 749px){
    font-size: 2.5em;
    }
    }
}
.lastfive-home, .lastfive-away {
display: flex;
font-family: 'Teko', sans-serif;
flex-direction: column;
justify-content: flex-start;
margin: 0 auto;
padding: 0px;
text-transform: uppercase;
ul{
padding: 0;
margin: 0 auto;
}
span{
padding: 0 5px;
@media screen 
and (max-width: 440px) {
  padding: 0 1px;
}
}
li{
list-style: none;
padding: 10px 0;
font-size: 2em;

@media screen 
and (min-width: 320px)
and (max-width: 400px)  {
font-size: .78em;
padding:  2px 0;
}
@media screen 
and (min-width: 401px)
and (max-width: 430px)  {
font-size: 1em;
padding: 8px 0;
}
@media screen
and (min-width: 750px) 
and (max-width: 1000px){
font-size: 1.4em;
padding: 10px;
}
@media screen
and (min-width: 1001px) 
and (max-width: 1450px){
font-size: 1.2em;
padding: 10px 5px;
}
@media screen
and (min-width: 1451px) 
and (max-width: 1700px){
font-size: 1.35em;
padding: 15px 0;
}
@media screen
and (min-width: 431px) 
and (max-width: 749px){
font-size: 1em;
}
@media screen 
and (min-width: 2000px){
  font-size: 2.5em;
}
@media screen 
and (min-width: 2000px){
  font-size: 3em;
}
}
@media screen 
and (max-width: 440px) {
h2{
font-size: 1.2em;
margin: 5px 15px;
}
}
}
.lastfive-home:last-child, .lastfive-away:last-child{
  margin-bottom: 20px;
}
.lastfive-home{
  grid-area: home;
}
.lastfive-away{
  grid-area: away;
}
.homeImg{
  grid-area: homeImg;
}
.awayImg{
  grid-area: awayImg;
}
.homeImg, .awayImg{ 
  user-select: none;
  display: flex;
  @media screen 
  and (max-width: 1100px) {
  display: none;
  }
}
//Head to Head Component
.pages{
margin: 0 auto;
font-family: 'Bebas Neue', cursive;
ul{
display: flex;
flex-direction: row;
li{
  padding: 5px 20px;
  margin: 0 10px;
  border: 2px solid #fff;
  border-radius: 10px;
  list-style: none;
  font-size: 2em;
  a {
    color: #FFF;
  }
  @media screen 
  and (max-width: 430px){
    font-size: .8em;
    margin: 0 3px;
    padding: 3px 9px;
  }
  @media screen
  and (min-width: 431px) 
  and (max-width: 1250px){
    font-size: 1em;
    margin: 5px;
    padding: 5px 12px;
  }
}
}
li:hover{
background-color: deeppink;
}
}

.headToHead-list{
display: flex;
text-transform: uppercase;
justify-content: center;
width: 88vw;
margin: 0 auto;
ul{
padding: 0;
}
li{
display: grid;
grid-template-columns: .5fr .2fr 1.5fr 1fr 2fr .5fr;
gap: 5px;
padding: 5px 0;
font-size: 2.2em;
width: 100%;
margin: 5px 0;
@media screen 
and (max-width: 430px){
  gap: 0;
  font-size: .73em;
  grid-template-columns: 1fr .2fr 3fr 1fr 3fr .1fr;
}
@media screen
and (min-width: 431px) 
and (max-width: 530px){
  font-size: .85em;
}
@media screen
and (min-width: 531px) 
and (max-width: 749px){
  font-size: 1.2em;
}
@media screen
and (min-width: 750px) 
and (max-width: 1099px){
font-size: 1.7em;
padding: 10px 0;
}
@media screen
and (min-width: 1100px) 
and (max-width: 1250px){
font-size: 2em;
padding: 10px 0;
}
}
}
.headToHead-list li:last-child{
  margin-bottom: 15px;
}
.nextGames-list{
  display: flex;
  text-transform: uppercase;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  ul{
  padding: 0;
  }
  li{
  display: grid;
  grid-template-columns: .5fr .5fr .5fr 1fr .5fr;
  gap: 0;
  padding: 15px 0;
  font-size: 1.8em;
  width: 100%;
  margin: 25px 0;
  span{
    padding: 0 10px;
  }
  @media screen 
  and (max-width: 430px){
    font-size: .69em;
    padding: 0;

  }
  @media screen
  and (min-width: 431px) 
  and (max-width: 749px){
    font-size: 1em;
  }
  @media screen
  and (min-width: 750px) 
  and (max-width: 1099px){
  font-size: 1.5em;
  padding: 0;
  }
  @media screen
  and (min-width: 1100px) 
  and (max-width: 1350px){
  font-size: 1.2em;
  padding: 0;
  }
  @media screen
  and (max-width: 1250px){
    grid-template-columns: .1fr .1fr  1fr ;
  }
  }
}
.nextGames-link{
  color: #fff;
}
.nextGames-link:hover{
  color: deeppink;
}
.nextgames-imgs{
  width: 35%;
  height: 35%;
  margin-top: -15px;
  @media screen
  and (max-width: 1250px){
    display: none;
  }
}
.LeagueLogo{
  width: 20%;
  height: 20%; 
  margin-top: -15px;
  @media screen 
  and (min-width: 320px)
  and (max-width: 550px){
  margin-top: -20px;
  width: 35%;
  height: 35%; 
}
  @media screen 
  and (max-width: 320px){
  margin-top: -10px;
  width: 35%;
  height: 35%; 
}
  @media screen and (min-width: 2000px)
  and (max-width: 2500px){
  margin-top: -50px;
}
  @media screen 
  and (min-width: 2500px){
  margin-top: -60px;
}
}
.nextgames-header{
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 50vw;
  height: 15vh;
  h2{
  font-size: 2em;
  }
  @media screen 
  and (max-width: 1050px) {
  height: 5vh;
  margin-top: 15px;
  }
  @media screen and (min-width: 1050px)
  and (max-width: 1400px){
  width: 60vw;
  height: 13vh;
}
  @media screen 
  and (max-width: 450px){
  width: 60vw;
  height: 5vh;
}
}
.nextgames-liga{

@media screen 
and (min-width: 800px) {
width: 20vw;
font-size: 1.5em;
margin-top: -10px;
}
}
.game-date{
font-size: 0.7em;
margin-top: 13px;
@media screen 
and (max-width: 430px){
margin: 2px;
font-size: 0.75em;
}
@media screen
and (min-width: 431px) 
and (max-width: 749px){
margin: 5px;
}
@media screen
and (min-width: 750px) 
and (max-width: 1099px){
margin: 5px;
}

}
.score-board{
margin: 5px 50px;
background: rgb(255,255,255);
background: linear-gradient(180deg, rgba(255,255,255,1) 41%, rgba(126,126,126,1) 100%);
width: 120px;
height: 50px;
display: flex;
justify-content: center;
border-radius: 5px;
font-size: 1em;
color: #000;

span{
margin: 0 5px;
}
@media screen 
and (max-width: 430px){
width: 40px;
height: 20px;
margin: 0px 6px;
span{
  margin: 4px 1px;
}
}
@media screen
and (min-width: 431px) 
and (max-width: 749px){
width: 60px;
height: 25px;
margin: 0 6px;
span{
  margin: 4px 2px;
}
}
@media screen
and (min-width: 750px) 
and (max-width: 1099px){
width: 80px;
height: 30px;
margin: 0px 10px;
span{
  margin: 0 2px;
}
}
@media screen
and (min-width: 1100px) 
and (max-width: 1250px){
width: 85px;
height: 40px;
margin: 0px 10px;
}
}
.toHead-content{
display: flex;
flex-direction: column;
padding-bottom: 20px;
h2{
font-size: 5em;
margin: 50px auto;
font-family: 'Bebas Neue', cursive;
@media screen 
and (max-width: 1100px) {
font-size: 2em;
margin: 5px auto;
}
@media screen 
and (max-width: 600px) {
font-size: 1em;
margin: 5px auto;
}
@media screen 
and (min-width: 1100px)
and (max-width: 1200px) {
font-size: 3.5em;
}
@media screen 
and (min-width: 750px) 
and (max-width: 1000px) {
font-size: 2.5em;
}
}
li{
list-style: none;
font-family: 'Teko', sans-serif;
}
}
.result{
margin: 0 20px ;
@media screen 
and (max-width: 430px) {
margin: 0 5px;

}
@media screen
and (min-width: 431px) 
and (max-width: 1099px){
margin: 0 3px;
}

}
//Progress Bars Head to Head
.bars_div, .bars_div_over{
display: flex;
flex-direction: column;
grid-area: bars;

h2{
  margin: 0 auto;
}
}

.subbars_div{
display: flex;
flex-direction: column;
margin-top: -40px;
@media screen 
and (max-width: 750px){
margin-top: -5px;
padding-bottom: 60px ;
}
@media screen 
and (min-width: 751px) 
and (max-width: 1300px) {
padding-bottom: 20px;
}
}
.general-progress{
padding: 20px 0;
margin: 0 auto;
div{
  margin: 5px 0;
}
@media screen 
and (max-width: 430px){
padding: 0;
margin: 0 auto;
}
@media screen
and (min-width: 431px) 
and (max-width: 749px){
padding: 0;
}
}
.general-progress:last-child{
  margin-bottom: 15px;
}
.progress-general {
background: rgb(218,0,0);
background: linear-gradient(180deg, rgba(218,0,0,1) 30%, rgba(91,18,18,1) 77%);
border-radius: 10px;
margin: 30px 0;
height: 30px;
width: 75vw;
display: flex;
align-items: center;
justify-content: flex-start;
position: relative;
font-family: 'Teko', sans-serif;
p{
position: absolute;
right: 30px;
top: 2px;
font-size: 20px;
@media screen
and (max-width: 530px){
  font-size: 15px;
  top: 3px;
  right: 5px;
}
}
@media screen 
and (max-width: 650px){
  margin: 15px 0;
}
}

.progress-over {
background: rgb(218,0,0);
background: linear-gradient(180deg, rgba(218,0,0,1) 30%, rgba(91,18,18,1) 77%);
border-radius: 10px;
margin: 30px 0;
height: 30px;
width: 65vw;
display: flex;
align-items: center;
justify-content: flex-start;
position: relative;
font-family: 'Teko', sans-serif;
p{
position: absolute;
right: 30px;
top: 2px;
font-size: 20px;
}
@media screen 
and (min-width: 771px) 
and (max-width: 1300px) {
margin: 5px;
}
@media screen 
and (max-width: 770px){
width: 65vw;
height: 20px;
margin: 0;

p{
  font-size: 15px;
  top: -2px;
  right: 5px;
}

}
}

.progress-done-over {
background: rgb(0,196,219);
background: linear-gradient(180deg, rgba(0,196,219,1) 6%, rgba(18,16,90,1) 88%);
display: flex;
align-items: flex-start;
justify-content: flex-start;
height: 100%;
transition: 1s ease 0.3s;
border-radius: 10px 0 0 10px;
span{
position: absolute;
left: 30px;
top: 2px;
font-size: 20px;
@media screen 
and (max-width: 430px){
  font-size: 15px;
  top: -2px;
  left: 5px;
}
@media screen
and (min-width: 431px) 
and (max-width: 749px){
  font-size: 10px;
  top: 0px;
  left: 5px;
}
@media screen
and (min-width: 750px) 
and (max-width: 1099px) {
top: 0;
font-size: 15px;
left: 10px;
}
}
}
.progress-done-general {
background: rgb(0,196,219);
background: linear-gradient(180deg, rgba(0,196,219,1) 6%, rgba(18,16,90,1) 88%);
display: flex;
align-items: flex-start;
justify-content: flex-start;
height: 100%;
transition: 1s ease 0.3s;
border-radius: 10px 0 0 10px;
span{
position: absolute;
left: 30px;
top: 2px;
font-size: 20px;
@media screen
and (max-width: 530px){
  font-size: 15px;
  top: 3px;
  left: 5px;
}
}
}
.progress-draw{
  background: rgb(223,215,0);
  background: linear-gradient(180deg, rgba(223,215,0,1) 54%, rgba(129,130,69,1) 100%);
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  span{
  left: 25px;
  margin-top: 2px;
  font-size: 20px;
  @media screen 
  and (max-width: 530px){
    font-size: 15px;
    margin-top: 3px;
  }
  }
}

.progress-data-general{
color: #FFF600;
margin: 13px 5px;
}
.progress-titles{
display: flex;
flex-direction: row;
justify-content: space-between;
margin-bottom: -30px;
font-family: 'Bebas Neue', cursive;
@media screen 
and (min-width: 1001px) 
and (max-width: 1300px){
margin: 0;
}

@media screen 
and (min-width: 441px) 
and (max-width: 1000px){
margin-bottom: -5px;
h3{
font-size: 1em;
}
}
@media screen 
and (min-width: 400px) 
and (max-width: 440px){
margin-bottom: -10px;
h3{
font-size: 1em;
margin: 0 6px;
}
}
@media screen 
and (max-width: 399px){
margin-bottom: -10px;
h3{
font-size: 1em;
margin: 0 3px;
}
}
}
//Player pag
.playerHeader{
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.playerTitle{
  width: 100%;
  margin: auto;
  h2{
  margin: 0 auto;
  width: fit-content;
  font-family: 'Bebas Neue', cursive;
  color: #FFF600;
  font-size: 5em;
  }
  h3{
    margin: 0 auto;
  }
  @media screen 
  and (max-width: 670px){
    h2{
      font-size: 3em;
    }
    h3{
      font-size: 1.5em;
    }
  }}
.filterForm{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  justify-content: center;
  width: fit-content;
  background-color: #000;
  
  label > input{
  visibility: hidden;
  }
  label > input + img{
  cursor:pointer;
  border:4px solid #EEE;
  border-radius:15px;
  padding: 5px;
  filter: brightness(50%);
  }
  label > input:checked + img{
  border:4px solid #FFF;
  filter: brightness(100%);
  }
}


.filterHeader{
  background: none;
  border: none;
  color: #FFF;

  h5{
  width: fit-content;
  margin: auto;
  font-family: 'Bebas Neue', cursive;
  color: #fff;
  
  }
  @media screen 
  and (max-width: 399px){
  h1{
    font-size: 2.5em;
  }
}
}

.leagueSelect{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  justify-content: center;
  width: fit-content;
  margin-bottom: 25px;
  label > input{
    visibility: hidden;
  }
  label > input + img{
    cursor:pointer;
    border:4px solid #EEE;
    border-radius:15px;
    padding: 5px;
    filter: brightness(50%);
  }
  label > input:checked + img{
    border:4px solid #FFF;
    filter: brightness(100%);
  }
@media screen 
and (max-width: 500px){
  grid-template-columns: 1fr 1fr 1fr ;
}
}


.searchLogo{
width: 150px;
@media screen 
and (max-width: 1000px){
width: 100px;
}
@media screen 
and (max-width: 500px){
width: 85px;
}
@media screen 
and (max-width: 340px){
width: 75px;
}
}

.rah-hint-wrapper, .rah-text-filler, .rah-hint {
  color: #FFF600 !important;
}
.closedMarket{
  font-size: 1em;
  width: 5vw;
  margin: 6% auto;
  color: red;
  @media screen 
  and (max-width: 1000px){
  font-size: 14px;
  line-height: 14px;
}
@media screen 
and (max-width: 850px){
font-size: 12px;
width: 10vw;
}
@media screen 
and (max-width: 400px){
font-size: 14px;
width: 15vw;

}
}
.prelive{
  color: #fff;
  position: absolute;
  top: 10%;
  right: 1%;
  border: 1px solid red;
  width: fit-content;
  height: fit-content;
  margin-top: 5px;
  padding: 1px;
  background-color: red;
  border-radius: 5px;
  @media screen 
  and (min-width: 1900px){
  top: 18%;
}
@media screen 
and (max-width: 430px) {
  top: 16%;
}
  h4{
    font-size: .9em;
  }
}
.pvpmatchs{
  position: relative;
  width: fit-content;
  margin: 0 auto;
  h4{
    font-size: 1.3em;
    color: #FFF;
    font-family: 'Bebas Neue', cursive;
    font-style: italic;
  }
}