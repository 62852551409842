.btn {
border-radius: 5px;
padding: 5px 15px;
margin-bottom: 20px;
margin-right: 15px;
transition: all 0.4s;
font-size: 14px;
position: relative;
overflow: hidden;
z-index: 0;
margin-top: 8px;

&:last-child {
margin-right: 0;
}

&:before {
position: absolute;
height: 0;
width: 0;
background-color: $color-accent;
transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
transform: translate(-50%, -50%);
z-index: -1;
content: "";
top: 0;
left: 0;
}

p {
display: flex;
transition: all 0.3s;
font-weight: 500;
color: #444444;
}

svg {
height: 14px;
width: 14px;
margin: 2px 5px 0 0;
transition: all 0.3s;
fill: #444444;
}

&:hover, &:focus, &:active, &:active:focus {
outline: none;
box-shadow: none !important;

&:before {
height: 500%;
width: 225%;
}
}

&:focus, &:active, &:active:focus {

&:before {
transition: all 0s;
}
}

&.square {
border-radius: 0;
}

&.rounded {
border-radius: 30px !important;
}

&.icon {
padding: 10px 15px;

&:hover {

p {
  color: #646777;
}

svg {
  fill: #646777;
}
}

&.icon--right {

svg {
  margin: 2px 0 0 5px;
}
}
}

&.btn-sm {
padding: 5px 25px;
font-size: 14px;
}

&.btn-lg {
padding: 12px 25px;
font-size: 14px;
}

&.btn-secondary {
background-color: #e7e2e2;
border-color: #e7e2e2;
color: #646777;

&:before {
background-color: darken(#e7e2e2, 10%);
}

&:hover, &:focus, &:active, &:active:focus {
border-color: darken(#e7e2e2, 10%);
color: #646777;
}

&:not([disabled]):not(.disabled):active, &:not([disabled]):not(.disabled).active {
background-color: #dddddd;
border-color: #dddddd;
}
}

&.btn-outline-secondary {
border-color: #e7e2e2;

&, p {

@include themify($themes) {
  color: themed('colorText');
}
}

svg {

@include themify($themes) {
  fill: themed('colorText');
}
}

&:before {
background-color: #e7e2e2;
}

&:hover, &:focus, &:active, &:active:focus {
background: transparent;
border-color: #e7e2e2;
color: #444444;

p {
  color: #444444;
}

svg {
  fill: #444444;
}
}
}

&.btn-primary {
background-color: $color-blue;
border-color: $color-blue;

&:before {
background-color: $color-blue-hover;
}

&:hover, &:focus, &:active, &:active:focus {
border-color: $color-blue-hover;
}
}

&.btn-outline-primary {
color: $color-blue;
border-color: $color-blue;

p {
color: $color-blue;
}

svg {
fill: $color-blue;
}

&:before {
background-color: $color-blue;
}
}

&.btn-success {
background-color: #16882f;
border-color: #16882f;


&:before {
background-color: #105A20;
}

&:hover, &:focus, &:active, &:active:focus {
border-color: #16882f;
}
}

&.btn-outline-success {
color: $color-accent;
border-color: $color-accent;

p {
color: $color-accent;
}

svg {
fill: $color-accent;
}

&:before {
background-color: $color-accent;
}
}

&.btn-warning {
background-color: $color-yellow;
border-color: $color-yellow;
color: #ffffff;

&:before {
background-color: $color-yellow-hover;
}

&:hover, &:focus, &:active, &:active:focus {
border-color: $color-yellow-hover;
}
}

&.btn-outline-warning {
color: $color-yellow;
border-color: $color-yellow;

p {
color: $color-yellow;
}

svg {
fill: $color-yellow;
}

&:before {
background-color: $color-yellow;
}
}

&.btn-danger {
background-color: $color-red;
border-color: $color-red;

&:before {
background-color: $color-red-hover;
}

&:hover, &:focus, &:active, &:active:focus {
border-color: $color-red-hover;
}
}

&.btn-outline-danger {
color: $color-red;
border-color: $color-red;

p {
color: $color-red;
}

svg {
fill: $color-red;
}

&:before {
background-color: $color-red;
}
}

&, &.btn-primary, &.btn-danger, &.btn-warning, &.btn-success, &.btn-outline-secondary, &.btn-secondary,
&.btn-outline-primary, &.btn-outline-danger, &.btn-outline-warning, &.btn-outline-success,
&.icon, &.icon.btn-secondary {

&.disabled {
background-color: #f2f4f7;
border-color: #f2f4f7;
color: #dddddd;
pointer-events: none;

p {
  color: #dddddd;
}

svg {
  fill: #dddddd;
}

}
}

&.btn-primary, &.btn-danger, &.btn-warning, &.btn-success {
p {
color: #ffffff;
}

svg {
fill: #ffffff;
}
}

&.btn-outline-primary, &.btn-outline-danger, &.btn-outline-warning, &.btn-outline-success {

&:hover, &:focus, &:active, &:active:focus {
color: #ffffff;
background: transparent;
}
}

&.btn-primary, &.btn-danger, &.btn-warning, &.btn-success,
&.btn-outline-primary, &.btn-outline-danger, &.btn-outline-warning, &.btn-outline-success {

&:hover, &:focus,
&:active, &:active:focus {

p {
  color: #ffffff;
}

svg {
  fill: #ffffff;
}
}

}

&.expand {

svg {
width: 0;
transition: all 0.3s;
}

&.expand--load {

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
svg {
  width: 14px;
  animation: rotating 2s linear infinite;
}
}
}
}

.btn-toolbar {
margin-top: 15px;
margin-bottom: 10px;

& > * {
margin-right: 15px;

&:last-child {
margin-right: 0;
}
}

&:first-child {
margin-top: 0;
}

&:last-child {
margin-bottom: -10px;
}

&.btn-toolbar--center {

& > * {
margin-right: auto;
margin-left: auto;
}
}
}

.btn-group {
border-radius: 5px;
margin-bottom: -10px;

.btn {
margin-right: 0;
padding: 10px 15px;
font-weight: 500;
}

&.btn-group--justified {
display: flex;
justify-content: space-between;
width: 100%;

.btn {
width: 100%;
}
}

&.btn-group--icons {

.btn {
padding: 7px 8px;
line-height: 14px;
}
}

&.open .dropdown-toggle {
box-shadow: none;
}
}

button:focus, button:active {
outline: none;
}

.open > .dropdown-toggle.btn-default,
.btn-default:active:focus, .btn-default:active:focus,
.btn-default.focus, .btn-default:focus {

@include themify($themes) {
background-color: themed('colorBackground');
}
}

.bet_btn{
background: none;
border: none;
width: 65px;
height: 65px;
margin: 5px 5px 0 5px;
@media screen 
and (max-width: 430px) {
width: 55px;
height: 55px;
}
@media screen 
and (max-width: 1000px){
  position: relative;
  left: 70px;
  top: 4px;
}
@media screen 
and (min-width: 1000px)
and (max-width: 1199px){
width: 60px;
height: 60px;
position: relative;
left: 90px;
top: 5px;
}
@media screen 
and (min-width: 1200px)
and (max-width: 1499px) {
  width: 60px;
  height: 70px;
}
@media screen 
and (min-width: 1500px)
and (max-width: 1800px){
width: 65px;
height: 65px;
}
@media screen 
and (min-width: 1801px)
and (max-width: 1999px){
  width: 30%;
  height: 30%;
  margin-top: 18px;
}
@media screen
and (min-width: 2000px)
and (max-width: 2300px){
  width: 28%;
  height: 28%;
  margin-top: 15px;
}
@media screen
and (min-width: 2300px) {
  width: 25%;
  height: 25%;
  margin-top: 10px;
}
}
.bet_dutching{
position: absolute;
background: none;
border: none;
top: 35px;
right: 20px;
width: 65px;
height: 65px;
@media screen 
and (max-width: 410px) {
top: 43px;
right: -10px;
width: 45px;
height: 45px;
}
@media screen 
and (min-width: 411px) 
and (max-width: 630px){
top: 35px;
right: 15px;
width: 55px;
height: 55px;
}
@media screen 
and (min-width: 640px)
and (max-width: 999px) {
top: 33px;
right: 10px;
width: 65px;
height: 65px;
}
@media screen 
and (min-width: 1000px) {
top: 32px;
right: 5px;
width: 65px;
height: 65px;
}
@media screen 
and (min-width: 1800px)
and (max-width: 1999px){
top: 35px;
width: 65px;
height: 65px;
}
@media screen
and (min-width: 2000px)
and (max-width: 2299px){
  top: 60px;
  width: 65px;
  height: 65px;
  right: -200px;
}
@media screen
and (min-width: 2300px){
  top: 60px;
  width: 65px;
  height: 65px;
  right: -295px;
}
}

.submit_head{
  color: #FFF;
  display: flex;
  background: none;
  justify-content: center;
  margin: 0 5px 20px 5px ;
  border: 2px solid #FFF;
  padding: 20px 15px;
  border-radius: 15px;
  grid-area: submit-btn;
  font-size: 23px;

  @media screen 
  and (max-width: 1000px){
    padding: 10px 20px;
    margin: 20px auto;
  }
}
.submit_head:hover{
  border: 2px solid #FFF600;
  color: #FFF600;
}

.calc_btn{
  background-color: #000;
  border: 2px solid #FFF;
  color: #FFF;
  padding: 10px 30px;
  border-radius: 15px;
  text-transform: uppercase;
  font-weight: bold;

  @media screen 
  and (max-width: 620px){
  padding: 0 2px;
  font-size: 10px;
}
}
.calc_btn:hover{
  border: 2px solid #FFF600;
  color: #FFF600;
}

.theme-button{
background: none;
position: fixed;
box-shadow: none;
border: none;
right: 115px;
top: 25px; 
z-index: 1000;
}

.visibility_btn{
position: absolute;
top: -13px;
right: -10px;
background: none;
color: #fff;
border: none;
padding: 15px;
@media screen 
and (min-width: 2000px){
top: -17px;
}
}
.visibility_headtohead{
position: absolute;
top: 0px;
right: 10px;
background: none;
color: #fff;
border: none;
padding: 15px;
@media screen 
and (max-width: 430px) {
right: 0;
top: -15px;
}
}
.visibility_headtohead_closed{
  position: absolute;
  top: 8px;
  right: 32%;
  background: none;
  color: #fff;
  border: none;
  padding: 15px;
  @media screen 
  and (max-width: 430px) {
  right: 19%;
  top: -10px;
  }
  @media screen 
  and (min-width: 431px)
  and (max-width: 540px){
  right: 19%;
  top: 10px;
  }
  @media screen 
  and (min-width: 541px)
  and (max-width: 700px){
  right: 25%;
  top: 10px;
  }
  @media screen 
  and (min-width: 701px)
  and (max-width: 830px){
  right: 28%;
  top: 8px;
  }
  @media screen 
  and (min-width: 1400px)
  and (max-width: 2000px){
  right: 40%;
  top: 8px;
  }
  @media screen 
  and (min-width: 2000px){
  right: 43%;
  top: 8px;
  }
}

.sub-toggle-close{
background: none;
color: #fff;
border: none;
width: 50px;
padding: 10px;
border: 2px solid #fff;
border-radius: 10px;
display: flex;
justify-content: center;
margin: 0 auto;
position: absolute;
bottom: 10px;
right: 49%;
@media screen 
and (max-width: 430px) {
width: 25px;
height: 30px;
padding: 5px;
border-radius: 5px;
right: 48%;
}
@media screen 
and (min-width: 430px){

}
}
.sub-toggle-close:hover{
background-color: deeppink;
}
.sub-toggle-open{
background: none;
color: #fff;
border: none;
width: 50px;
padding: 10px;
border: 2px solid #fff;
border-radius: 10px;
display: flex;
justify-content: center;
margin: 0 auto;
@media screen 
and (max-width: 430px) {
width: 25px;
height: 25px;
padding: 5px;
border-radius: 5px;
margin: 10px auto;
}
}
.sub-toggle-open:hover{
background-color: deeppink;
}

.modal_btn{
  display: flex;
  justify-content: center;
  font-size: 14px;
  width: 115px;
  height: 38px;
  padding: 10px;
  margin: 20px 0px 20px -30px ;
  color: #fff;
  background: #000 ;
  border: 2px solid #FFF; 
  border-radius: 100px;
  p{
  font-family: 'Bebas Neue', cursive;
  font-style: italic;
}
@media screen 
and (max-width: 1199px){
  margin: 20px -70px;
}
}
.filter_btn{
  position: absolute;
  right: 80px; 
  bottom: 10px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  width: 115px;
  height: 38px;
  padding: 15px;
  margin: 20px 0px;
  color: #fff;
  background: #000 ;
  border: 2px solid #FFF; 
  border-radius: 100px;
  p{
  font-family: 'Bebas Neue', cursive;
  font-style: italic;
  font-size: 1.2em;
  position: relative;
  bottom: 5px;
}
@media screen 
and (max-width: 500px){
  width: 60px;
  height: 20px;
  right: 70px; 
  bottom: -18px;
  padding: 13px;
  p{
    position: relative;
    bottom: 7px;
  }
}
@media screen 
and (max-width: 1199px){
  margin: 20px -60px;
}
}
.filter_btn:hover{
  border: 2px solid #FFF600; 
  border-radius: 100px;
  p{
    color: #FFF600;
  }
}

.modal_btn_headtohead{
// position: absolute;
display: flex;
justify-content: center;
width: 85px;
height: 35px;
font-size: 10px;
padding: 10px;
margin: 20px;
color: #fff;
background: #000 ;
border: 2px solid #FFF; 
border-radius: 100px;
p{
top: 11px;
font-family: 'Bebas Neue', cursive;
font-style: italic;
}
@media screen 
and (max-width: 430px){
  width: 60px;
  height: 35px;
  margin: 20px 0 0 0;
}
}
.headtohead2{

position: absolute;
display: flex;
justify-content: center;
width: 85px;
height: 35px;
font-size: 10px;
padding: 10px;
right: 10px;
bottom: 5px;
color: #fff;
background: #000 ;
border: 2px solid #FFF; 
border-radius: 100px;
p{
position: absolute;
top: 11px;
font-family: 'Bebas Neue', cursive;
font-style: italic;
}
@media screen 
and (max-width: 430px) {
width: 60px;
height: 25px;
p{
position: absolute;
top: 5px;
}
}
@media screen 
and (min-width: 431px)
and (max-width: 780px) {
width: 60px;
height: 25px;
p{
position: absolute;
top: 6px;
}
}
@media screen 
and (min-width: 1200px)
and (max-width: 1999px) {
width: 65px;

}
@media screen 
and (min-width: 2000px){
width: 130px;
height: 40px;
font-size: 1em;
p{
top: 10px;

}
}

}
.bet_head{
background: none;
border: none;
width: 65px;
height: 65px;
@media screen 
and (max-width: 430px) {
width: 50px;
height: 50px;
margin-top: 12px;
}
}

.modal_btn:hover, .modal_btn_headtohead:hover {
border: 3px solid #FFF600;
color: #FFF600;
}
.login_btn{
  background-color: #105A20;
  color: #FFF;
  @media screen 
  and (max-width: 1700px){
}
}
.login_btn:hover{
  color: #FFF600;
  background-color: #105A20;
}